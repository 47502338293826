
import React from 'react';
import GeneralContent from '../components/general-content';

import LandingContent from "../../content/landing-content.yaml"


export default function About() {
    let header = LandingContent.aboutHeader
    let title = LandingContent.aboutTitle
    let content = LandingContent.aboutContent
    return (
        <GeneralContent header={header} title={title} content={content}></GeneralContent>
    )
}
